<template>
  <div>
    <h4>Books Archive</h4>
    <hr class="books_archieve__bottom" />
     <div class="language-filter " v-if="archivedBookData != '' && filteredArchivedBooks.length != 0">

      <div class="container">

              <div class="book-title" v-if="isBookOpened">{{ bookTitle }}</div>
      </div>
    </div>

    <h6 v-if="filteredArchivedBooks.length == 0">Please Search something...</h6>
     <div class="bookReader" v-if="isBookOpened">

        <i class="box-icon bx bx-x selected-content__close" @click="goBack()"></i>
          <iframe
          class="iframe"
          id="iframe"
          title="Books Archive"
          :src="dataUrl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>

      <hr class="books_archieve__bottom" />
    </div>

    <div class="language-filter " v-if="archivedBookData != '' && filteredArchivedBooks.length != 0">

     <div class="container">

        <ed-dropdown class="language-dropdown"
                :listItems="uniqueLanguages"
                :selected="selectedLanguage"
                :isSelected="selectLanguage"
              />
      </div>

      </div>
    <div class="archive-lib__container">
      <div class="archive-lib__books">
        <div
          class="archive-lib__book-container"
          v-for="book in filteredArchivedBooks"
          :key="book.identifier"
        >
         <div class="archive-lib__book card">
  <div class="card-body d-flex justify-content-between align-items-center">
    <div class="d-flex flex-column">
      <h2 class="archive-lib__book-title mb-3">
        {{ book.title.substring(0, 80) }}
      </h2>
      <div class="">
        <p class="archive-lib__date mb-2">
          <span class="archive-lib__date--title">Published on</span
          ><span class="archive-lib__date--value">{{
            machineToHuman(book.date)
          }}</span>
        </p>
        <p><strong>Lang</strong>: {{ book.language }}</p>
        <p>
          <strong>Creator</strong>
          <span v-if="typeof book.creator === 'object'">
            <div v-for="(creator, index) of book.creator" :key="creator">
              <span v-if="index < 1">{{ creator }},</span>
            </div>
          </span>
                <read-more
                  v-else
                  more-str="read more"
                  :text="book.creator"
                  link="#"
                  less-str="read less"
                  :max-chars="0"
                ></read-more>
              </p>
            </div>

            <div class="archieve-lib__description">
              <strong>Description</strong>:
              <read-more
                more-str="read more"
                :text="getDescription(book.description)"
                link="#"
                less-str="read less"
                :max-chars="10"
              ></read-more>
            </div>
          </div>
            <button
              class="btn btn-secondary btn-bottom-right"
              @click="openBook(book)"
            >
              Open
            </button>
          </div>
        </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import edDropdown from '@/common/components/ed-forms/ed-dropdown.vue';

export default {
  name: 'ArchiveWidget',
  components: {
    edDropdown,
  },
  data() {
    return {
      dataUrl: '',
      bookTitle: '',
      isBookOpened: false,
      bookData: [],
      bookIndex: 0,
      selectedLanguage: '',
    };
  },

  computed: {
    exploreEntity() {
      return this.$store.state.current_selected_entity;
    },

    archivedBookData() {
      return this.$store.state.archivedData;
    },

    uniqueLanguages() {
      const languages = [...new Set(this.archivedBookData.map((book) => book.language))];
      return languages;
    },

    filteredArchivedBooks() {
      if (this.selectedLanguage) {
        return this.archivedBookData.filter((book) => book.language === this.selectedLanguage);
      }
      return this.archivedBookData;
    },
  },

  watch: {
    exploreEntity(val) {
      this.$store.dispatch('fetchArchiveBooks', val.word);
    },

  },

  methods: {
    selectLanguage(language) {
      this.selectedLanguage = language;
    },
    getDescription(desc) {
      return typeof desc === 'object' ? desc[0] : desc;
    },

    machineToHuman(time) {
      return moment(time).utc().format('MMMM DD YYYY');
    },

    openBook(payload) {
      console.log('identifier: ', payload);
      this.dataUrl = `https://archive.org/stream/${payload.identifier}`;
      this.bookTitle = payload.title;
      this.isBookOpened = true;
    },
    goBack() {
      this.isBookOpened = false;
    },
  },

};
</script>

<style lang="scss" scoped>

.archive-lib__books {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  overflow: auto;

}

.archive-lib__book {
  flex-direction: row;
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px;
  height: 93%;
  width: 342px;
  border-radius: 0px 28px 28px 0px;
}

.archive-lib__book-title {
  font-size: 1.2rem;
  font-weight: bolder;
  margin-bottom: 10px;
}

.card-body {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.archive-lib__date {
  margin-bottom: 5px;
}

.btn-secondary {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #0056b3;
}
.bookReader {
  max-height: 26rem;
  position: relative;
  width: 100%;
  margin-bottom: 270px;
}

.selected-content__close {
  float: right;
}
.iframe{
  width: 100%;
  height: 43rem;
}

.language-dropdown{
  margin-right: auto;
  margin-bottom: 10px;
  width: 14rem;
}

.container {
  display: flex;
  align-items: center;
}
.btn-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0px 0px 28px 0px;
}
.book-title{
  font-weight:530;
}
</style>
